import React from "react";
import { Icon } from "../../ui";
import { accordionFullData, journeyData, whyChooseData } from "./mock";
import { NavLink } from "react-router-dom";
import apk from "../../apk/scorefusion.apk";
import Button from "../../ui/elements/Button";

const Home = () => {
  return (
    <>
      {/* NAVBAR */}

      <section id="home">
        <div className="lightbg position-relative hero-sec">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8 col-md-10 text-center my-2">
                <h4>
                  <span></span>Welcome to Our Platform
                </h4>
                <h1>
                  <span>Play Fantasy Cricket </span>and Build Your Dream Team
                  Today!
                </h1>
                <p>
                  Welcome to scorefusion.com, Join our platform to create your
                  dream team, compete in exciting matches, and enjoy the
                  ultimate cricket experience!
                </p>
                <div className="mybtn my-2">
                  <Button className="primarybtn">Get Started</Button>
                </div>
                <div className="heroimg">
                  <Icon name="Heroimg" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container" id="usp">
        <div className="start_withmain comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  How to <span>Play ? </span>
                </h4>
                <p>
                  Experience the excitement of fantasy cricket with our app.
                  Download now and dive into the world of cricketing strategy,
                  team-building, and competition, all at your fingertips.
                </p>
              </div>
            </div>
          </div>

          <div className="start_withsub">
            {journeyData?.map((data, index) => {
              return (
                <div key={index} className="start_withbox">
                <div className="start_withicon w-lg-50 w-100 text-center">
                    <Icon name={data?.icon} className="img-fluid" />
                  </div>
                  <div className="w-lg-50 w-100">
                    <h5>{data?.title}</h5>
                    <p>{data?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section id="about" className="aboutus_main comman_space">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-7">
              <div className="aboutus_text">
                <h4>About US</h4>
                <p>
                  Owned and operated by XXXX XXXX XXXX.,
                  Scorefusion.com is an online fantasy game designed for the
                  fans who like to showcase their cricket knowledge and
                  analytical skills.
                </p>
                <p>
                  Here, you don a selector’s hat and create a playing XI of your
                  choice – a set of players you think will keep the scorers busy
                  and spectators on the edge of their seats. Here, you trust
                  your skills and create your own fortune – all with the help of
                  on-field players you placed your trust in.
                </p>
                <p>
                  You aren’t a mere spectator anymore. You get to be the manager
                  of your team.
                </p>
                <p>
                  Scorefusion.com is managed by a professional management team
                  with several years of experience in the online games industry.
                  To find out more about the job opportunities, work culture and
                  the life at XXXX XXXX XXXX
                </p>
                <NavLink to={"/about-us"} className="whitebtn d-inline-block">
                  Know More
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5 ">
              <div className="aboutus_img text-lg-center text-start">
                <Icon name="aboutushome" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section id="whychoose" className="comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Why Choose <span>us ?</span>
                </h4>
                <p>
                  Seamless experience, diverse contests, secure transactions,
                  and dedicated support for an unmatched fantasy cricket
                  journey.
                </p>
              </div>
            </div>
          </div>
          <div className="why_chooseboxmain">
            {whyChooseData.map((item, index) => (
              <div className="why_choosebox">
                <div className="whychoose_icon">
                  {/* <Icon name={item?.icon} className="img-fluid" /> */}
                  <span>{index + 1}</span>
                </div>
                <div>
                  <h5>{item?.title}</h5>
                  <p>{item?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* <section id="contact">
          <div className="contact_main">
            <div className="d-md-flex justify-content-between align-items-center d-block">
              <div className="contact_text">
                <h4>Contact Us</h4>
                <p>Get in touch with our team to learn more about</p>
                <Button className="primarybtn">Connect With Us</Button>
              </div>
              <div className="contact_icon">
                <Icon name="earn2" className="img-fluid" />
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="categories_swiper">
          <Swiper
            centeredSlides={false}
            spaceBetween={20}
            speed={900}
            parallax
            rewind
            loop
            effect="slide"
            freeMode
            grabCursor={true}
            watchSlidesProgress
            slidesPerView={3}
            breakpoints={{
              1200: { slidesPerView: 4 },
              992: { slidesPerView: 3 },
              768: { slidesPerView: 3 },
              576: { slidesPerView: 2 },
              0: { slidesPerView: 1.5 },
            }}
            autoplay={{
              delay: 19000,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {clientReview?.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="category_card ">
                    <Icon name="earn1" className="" />
                    <div className="play_cardtext">
                      <h4>{data?.title}</h4>
                      <p>{data?.productNo}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div> */}
      </div>

      <section>
        <div className="container">
          <div className="get_app">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-4 col-md-8">
                <h4>Get the App Now!</h4>
                <p>
                  Get the App Now!" and stay connected to your favorite sports.
                  Enjoy live scores, expert analysis, and exclusive content.
                  Dive into the world of fantasy sports with just a tap.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 text-md-end text-sm-start">
                <a className="primarybtn" href={apk} download="scorefusion.apk">
                  Download Free
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  FAQ’s About <span>scorefusion.com </span>
                </h4>
                <p>
                  Explore our FAQ section for answers to common queries about
                  our fantasy cricket platform
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 faq_icon">
              <Icon name="faqimg" className="img-fluid" />
            </div>
            <div className="col-lg-7">
              <div
                className="accordion accordion-flush selfaccord"
                id="accordionFlushExample"
              >
                {accordionFullData?.slice(0, 4)?.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={item.id}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}1`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}1`}
                      >
                        {item.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}1`}
                      className="accordion-collapse collapse"
                      aria-labelledby={item.id}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </>
  );
};

export default Home;
