export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "Register",
    description:
      "Sign up and create an account. Provide basic details such as name, email, and password. Verify your account.",
  },
  {
    icon: "tradingItemTwo",
    title: "Select a Match",
    description:
      "Choose an upcoming cricket fixture from the list of available matches. You can select matches from various leagues and tournaments.",
  },
  {
    icon: "tradingItemThree",
    title: "Create Your Team",
    description:
      "Build a squad of players within the budget and other constraints set by the platform. Select players based on their recent performance, form, and potential impact on the game.",
  },
  {
    icon: "tradingItemFour",
    title: "Join Contests",
    description:
      "Enter contests based on team performance. Compete with other fantasy cricket enthusiasts for prizes, including cash rewards, based on your team's performance in the actual match.",
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: "tradingItemOne",
    title: "Wide Range of Contests:",
    description:
      "We provide a variety of contests with different entry fees and prize pools, catering to all types of players, from beginners to experienced fantasy gamers.",
  },
  {
    icon: "tradingItemOne",
    title: "Dedicated Customer Support",
    description:
      "We offer dedicated customer support to assist users with any queries or issues they may encounter, ensuring a smooth and enjoyable experience for all players.",
  },
  {
    icon: "tradingItemOne",
    title: "Interactive Features",
    description:
      "Engage with other users through chat rooms, leaderboards, and interactive features, creating a vibrant and connected community.",
  },
  {
    icon: "tradingItemOne",
    title: "Customizable Contests",
    description:
      "Create and customize your own contests, inviting friends or joining public leagues to compete in a personalized setting.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is Scorefusion?",
    content:
      "Scorefusion is a fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with Scorefusion?",
    content:
      "Download the Scorefusion app from the Download App Button and create an account, then join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage of the app, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
