import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../ui";
import apk from "../../apk/scorefusion.apk";

const Header = () => {
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("  ");
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
    setTimeout(() => {
      setActiveSection(link);
      scrollToSection(link);
      closeNavbar();
    }, 0);
  };

  const scrollToSection = (div) => {
    const section = document.getElementById(div);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setActiveSection("home");
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 180 &&
          scrollPosition < sectionTop + sectionHeight - 50
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      console.log(window.location.hash);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };
  return (
    <>
      <div className="fixed-top darkbg navMain">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light self-nav">
            <div className="container-fluid justify-content-between ">
              <div>
                <p
                  className="navbar-brand"
                  onClick={() => {
                    closeNavbar();
                    handleLinkClick("home");
                  }}
                >
                  <Icon name="Logo" className="" />
                </p>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleNavbar}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse justify-content-center position-relative ${
                  isNavbarOpen ? "show" : ""
                }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li
                    className={`nav-item ${
                      activeSection === "home" ? "active" : ""
                    }`}
                  >
                    <p
                      className="nav-link"
                      aria-current="page"
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("home");
                      }}
                    >
                      Home
                    </p>
                  </li>
                  <li
                    className={`nav-item ${
                      activeSection === "usp" ? "active" : ""
                    }`}
                  >
                    <p
                      className="nav-link"
                      onClick={() => {
                        handleLinkClick("usp");
                        closeNavbar();
                      }}
                    >
                      How to Play
                    </p>
                  </li>
                  <li
                    className={`nav-item ${
                      activeSection === "about" ? "active" : ""
                    }`}
                  >
                    <p
                      className="nav-link"
                      onClick={() => {
                        handleLinkClick("about");
                        closeNavbar();
                      }}
                    >
                      About Us
                    </p>
                  </li>
                  <li
                    className={`nav-item ${
                      activeSection === "whychoose" ? "active" : ""
                    }`}
                  >
                    <p
                      className="nav-link"
                      onClick={() => {
                        handleLinkClick("whychoose");
                        closeNavbar();
                      }}
                    >
                      Why Choose Us ?
                    </p>
                  </li>
                </ul>
                {windowWidth < 991 ? (
                  <form className="navbtn">
                    <a
                      className="secondarybtn"
                      type="button"
                      //   onClick={() => openChatBox()}
                      href={apk}
                      download="scorefusion.apk"
                    >
                      Download App
                    </a>
                  </form>
                ) : (
                  ""
                )}
              </div>
              {windowWidth > 991 ? (
                <form className="mybtn">
                  <a
                    className="secondarybtn"
                    type="button"
                    // onClick={() => openChatBox()}
                    href={apk}
                    download="scorefusion.apk"
                  >
                    Download App
                  </a>
                </form>
              ) : (
                ""
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
